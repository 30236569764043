const firebaseConfig = {
  apiKey: "AIzaSyDHAJ-kFwWPyokiH9FGotl2yb5gOqQ0Stg",
  authDomain: "codenames-3a350.firebaseapp.com",
  databaseURL: "https://codenames-3a350.firebaseio.com",
  projectId: "codenames-3a350",
  storageBucket: "codenames-3a350.appspot.com",
  messagingSenderId: "632107391975",
  appId: "1:632107391975:web:3779fefbf143543f97bb42",
  measurementId: "G-Y88T3WM3NM",
};

export default firebaseConfig;
