const WordList = [
  "Acne",
  "Acre",
  "Addendum",
  "Advertise",
  "Aircraft",
  "Aisle",
  "Alligator",
  "Alphabetize",
  "America",
  "Ankle",
  "Apathy",
  "Applause",
  "Applesauce",
  "Application",
  "Archaeologist",
  "Aristocrat",
  "Arm",
  "Armada",
  "Asleep",
  "Astronaut",
  "Athlete",
  "Atlantis",
  "Aunt",
  "Avocado",
  "Backbone",
  "Bag",
  "Baguette",
  "Bald",
  "Balloon",
  "Banana",
  "Banister",
  "Baseball",
  "Baseboards",
  "Basketball",
  "Bat",
  "Battery",
  "Beach",
  "Beanstalk",
  "Bedbug",
  "Beer",
  "Beethoven",
  "Belt",
  "Bib",
  "Bicycle",
  "Big",
  "Bike",
  "Billboard",
  "Bird",
  "Bite",
  "Blacksmith",
  "Blanket",
  "Bleach",
  "Blimp",
  "Blossom",
  "Blueprint",
  "Blunt",
  "Blur",
  "Boa",
  "Boat",
  "Bob",
  "Body",
  "Bomb",
  "Bonnet",
  "Book",
  "Booth",
  "Bowtie",
  "Box",
  "Boy",
  "Brainstorm",
  "Brand",
  "Brave",
  "Bride",
  "Bridge",
  "Broccoli",
  "Broken",
  "Broom",
  "Bruise",
  "Brunette",
  "Bubble",
  "Buddy",
  "Buffalo",
  "Bulb",
  "Bunny",
  "Bus",
  "Buy",
  "Cabin",
  "Cafeteria",
  "Calculator",
  "Campsite",
  "Can",
  "Canada",
  "Candle",
  "Candy",
  "Cape",
  "Capitalism",
  "Car",
  "Cardboard",
  "Cartography",
  "Cat",
  "Ceiling",
  "Cell",
  "Century",
  "Chair",
  "Chalk",
  "Champion",
  "Charger",
  "Cheerleader",
  "Chef",
  "Chess",
  "Chew",
  "Chicken",
  "Chime",
  "China",
  "Chocolate",
  "Church",
  "Circus",
  "Clay",
  "Cliff",
  "Cloak",
  "Clockwork",
  "Clown",
  "Clue",
  "Coach",
  "Coal",
  "Coaster",
  "Cog",
  "Cold",
  "College",
  "Comfort",
  "Computer",
  "Cone",
  "Constrictor",
  "Continuum",
  "Conversation",
  "Cook",
  "Coop",
  "Cord",
  "Corduroy",
  "Cot",
  "Cough",
  "Cow",
  "Cowboy",
  "Crayon",
  "Cream",
  "Crisp",
  "Criticize",
  "Crow",
  "Cruise",
  "Crumb",
  "Crust",
  "Cuff",
  "Curtain",
  "Cuticle",
  "Czar",
  "Dad",
  "Dart",
  "Dawn",
  "Day",
  "Deep",
  "Defect",
  "Dent",
  "Dentist",
  "Desk",
  "Dictionary",
  "Dimple",
  "Dirty",
  "Dismantle",
  "Ditch",
  "Diver",
  "Doctor",
  "Dog",
  "Doghouse",
  "Doll",
  "Dominoes",
  "Door",
  "Dot",
  "Drain",
  "Draw",
  "Dream",
  "Dress",
  "Drink",
  "Drip",
  "Drums",
  "Dryer",
  "Duck",
  "Dump",
  "Dunk",
  "Dust",
  "Ear",
  "Eat",
  "Ebony",
  "Elbow",
  "Electricity",
  "Elephant",
  "Elevator",
  "Elf",
  "Elm",
  "Engine",
  "England",
  "Ergonomic",
  "Escalator",
  "Eureka",
  "Europe",
  "Evolution",
  "Extension",
  "Eyebrow",
  "Fan",
  "Fancy",
  "Fast",
  "Feast",
  "Fence",
  "Feudalism",
  "Fiddle",
  "Figment",
  "Finger",
  "Fire",
  "First",
  "Fishing",
  "Fix",
  "Fizz",
  "Flagpole",
  "Flannel",
  "Flashlight",
  "Flock",
  "Flotsam",
  "Flower",
  "Flu",
  "Flush",
  "Flutter",
  "Fog",
  "Foil",
  "Football",
  "Forehead",
  "Forever",
  "Fortnight",
  "France",
  "Freckle",
  "Freight",
  "Fringe",
  "Frog",
  "Frown",
  "Gallop",
  "Game",
  "Garbage",
  "Garden",
  "Gasoline",
  "Gem",
  "Ginger",
  "Gingerbread",
  "Girl",
  "Glasses",
  "Goblin",
  "Gold",
  "Goodbye",
  "Grandpa",
  "Grape",
  "Grass",
  "Gratitude",
  "Gray",
  "Green",
  "Guitar",
  "Gum",
  "Gumball",
  "Hair",
  "Half",
  "Handle",
  "Handwriting",
  "Hang",
  "Happy",
  "Hat",
  "Hatch",
  "Headache",
  "Heart",
  "Hedge",
  "Helicopter",
  "Hem",
  "Hide",
  "Hill",
  "Hockey",
  "Homework",
  "Honk",
  "Hopscotch",
  "Horse",
  "Hose",
  "Hot",
  "House",
  "Houseboat",
  "Hug",
  "Humidifier",
  "Hungry",
  "Hurdle",
  "Hurt",
  "Hut",
  "Ice",
  "Implode",
  "Inn",
  "Inquisition",
  "Intern",
  "Internet",
  "Invitation",
  "Ironic",
  "Ivory",
  "Ivy",
  "Jade",
  "Japan",
  "Jeans",
  "Jelly",
  "Jet",
  "Jig",
  "Jog",
  "Journal",
  "Jump",
  "Key",
  "Killer",
  "Kilogram",
  "King",
  "Kitchen",
  "Kite",
  "Knee",
  "Kneel",
  "Knife",
  "Knight",
  "Koala",
  "Lace",
  "Ladder",
  "Ladybug",
  "Lag",
  "Landfill",
  "Lap",
  "Laugh",
  "Law",
  "Lawn",
  "Lawnmower",
  "Leak",
  "Leg",
  "Letter",
  "Level",
  "Lifestyle",
  "Ligament",
  "Light",
  "Lightsaber",
  "Lime",
  "Lion",
  "Lizard",
  "Log",
  "Loiterer",
  "Lollipop",
  "Loveseat",
  "Loyalty",
  "Lunch",
  "Lunchbox",
  "Lyrics",
  "Machine",
  "Macho",
  "Mailbox",
  "Mammoth",
  "Mark",
  "Mars",
  "Mascot",
  "Mast",
  "Matchstick",
  "Mate",
  "Mess",
  "Mexico",
  "Midsummer",
  "Mine",
  "Mistake",
  "Modern",
  "Mold",
  "Mom",
  "Monday",
  "Money",
  "Monitor",
  "Monster",
  "Mooch",
  "Moon",
  "Mop",
  "Moth",
  "Motorcycle",
  "Mountain",
  "Mouse",
  "Mower",
  "Mud",
  "Music",
  "Mute",
  "Nature",
  "Negotiate",
  "Neighbor",
  "Neutron",
  "Niece",
  "Night",
  "Nightmare",
  "Nose",
  "Oar",
  "Observatory",
  "Office",
  "Oil",
  "Old",
  "Olympian",
  "Opaque",
  "Opener",
  "Orbit",
  "Organ",
  "Organize",
  "Outer",
  "Outside",
  "Ovation",
  "Overture",
  "Pail",
  "Paint",
  "Pajamas",
  "Palace",
  "Pants",
  "Paper",
  "Park",
  "Parody",
  "Party",
  "Password",
  "Pastry",
  "Pawn",
  "Pear",
  "Pen",
  "Pencil",
  "Pendulum",
  "Penny",
  "Pepper",
  "Personal",
  "Philosopher",
  "Phone",
  "Photograph",
  "Piano",
  "Picnic",
  "Pigpen",
  "Pillow",
  "Pilot",
  "Pinch",
  "Ping",
  "Pinwheel",
  "Pirate",
  "Plaid",
  "Plan",
  "Plank",
  "Plate",
  "Platypus",
  "Playground",
  "Plow",
  "Plumber",
  "Pocket",
  "Poem",
  "Point",
  "Pole",
  "Pomp",
  "Pong",
  "Pool",
  "Popsicle",
  "Population",
  "Portfolio",
  "Positive",
  "Post",
  "Princess",
  "Procrastinate",
  "Protestant",
  "Psychologist",
  "Publisher",
  "Punk",
  "Puppet",
  "Puppy",
  "Push",
  "Puzzle",
  "Quarantine",
  "Queen",
  "Quicksand",
  "Quiet",
  "Race",
  "Radio",
  "Raft",
  "Rag",
  "Rainbow",
  "Rainwater",
  "Ray",
  "Recycle",
  "Red",
  "Regret",
  "Reimbursement",
  "Retaliate",
  "Rib",
  "Riddle",
  "Rim",
  "Rink",
  "Roller",
  "Room",
  "Rose",
  "Round",
  "Roundabout",
  "Rung",
  "Runt",
  "Rut",
  "Sad",
  "Safe",
  "Salmon",
  "Salt",
  "Sandbox",
  "Sandcastle",
  "Sandwich",
  "Sash",
  "Satellite",
  "Scar",
  "Scared",
  "School",
  "Scoundrel",
  "Scramble",
  "Scuff",
  "Seashell",
  "Season",
  "Sentence",
  "Sequins",
  "Set",
  "Shaft",
  "Shallow",
  "Shampoo",
  "Shark",
  "Sheep",
  "Sheets",
  "Sheriff",
  "Shipwreck",
  "Shirt",
  "Shoelace",
  "Short",
  "Shower",
  "Shrink",
  "Sick",
  "Siesta",
  "Silhouette",
  "Singer",
  "Sip",
  "Skate",
  "Skating",
  "Ski",
  "Slam",
  "Sleep",
  "Sling",
  "Slow",
  "Slump",
  "Smith",
  "Sneeze",
  "Snow",
  "Snuggle",
  "Song",
  "Space",
  "Spare",
  "Speakers",
  "Spider",
  "Spit",
  "Sponge",
  "Spool",
  "Spoon",
  "Spring",
  "Sprinkler",
  "Spy",
  "Square",
  "Squint",
  "Stairs",
  "Standing",
  "Star",
  "State",
  "Stick",
  "Stockholder",
  "Stoplight",
  "Stout",
  "Stove",
  "Stowaway",
  "Straw",
  "Stream",
  "Streamline",
  "Stripe",
  "Student",
  "Sun",
  "Sunburn",
  "Swamp",
  "Swarm",
  "Sweater",
  "Swimming",
  "Swing",
  "Tachometer",
  "Talk",
  "Taxi",
  "Teacher",
  "Teapot",
  "Teenager",
  "Telephone",
  "Ten",
  "Tennis",
  "Thief",
  "Think",
  "Throne",
  "Thunder",
  "Tide",
  "Tiger",
  "Time ",
  "Tinting",
  "Tiptoe",
  "Tiptop",
  "Tired",
  "Tissue",
  "Toast",
  "Toilet",
  "Tool",
  "Toothbrush",
  "Tornado",
  "Tournament",
  "Tractor",
  "Train",
  "Trash",
  "Treasure",
  "Tree",
  "Triangle",
  "Trip",
  "Truck",
  "Tub",
  "Tuba",
  "Tutor",
  "Television",
  "Twang",
  "Twig",
  "Type",
  "Unemployed",
  "Upgrade",
  "Vest",
  "Vision",
  "Wag",
  "Water",
  "Watermelon",
  "Wax",
  "Wedding",
  "Weed",
  "Welder",
  "Wheelchair",
  "Whiplash",
  "Whisk",
  "Whistle",
  "White",
  "Wig",
  "Windmill",
  "Winter",
  "Wish",
  "Wolf",
  "Wool",
  "World",
  "Worm",
  "Wristwatch",
  "Yardstick",
  "Zamboni",
  "Zen",
  "Zero",
  "Zipper",
  "Zone",
  "Zoo"
];
const masterWordList = WordList.map(word => {
  return word.toUpperCase();
});

export default masterWordList;
